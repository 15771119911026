body,
html {
  margin: 0px;
  padding: 0px;
  font-family: sans-serif;
}

* {
  box-sizing: border-box;
}

.scroll-to-top {
  /* border: 1px solid rgb(155, 155, 155) !important; */
  background-color: #24b47a !important;
  border-radius: 3px !important;
  right: 20px !important;
  bottom: 20px !important;
}

.overall-container {
  /* width: 100vw; */
  height: 100vh;
  box-sizing: border-box;
}


.hero-slider {
  height: 70%;
  width: 100%;
  box-sizing: border-box;
}

.home-body {
  width: 100%;
  box-sizing: border-box;
}

.mySwiper {
  height: 100%;
  width: 100%;
}

.autoplay-progress {
  position: absolute;
  bottom: 20px;
  right: 10px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 14px;
  z-index: 10;
}

.autoplay-progress svg {
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
  stroke: #fff;
  stroke-width: 4;
  fill: none;
  stroke-dasharray: 126;
  stroke-dashoffset: calc(126 * var(--progress, 1));
}

.autoplay-progress circle {
  stroke: #1b8855;
}


@media screen and (min-width: 0px) and (max-width: 768px) {


  .css-1edfpdg-MuiTypography-root {
    font-weight: bold !important;
  }

  .hero-slider {
    height: 40%;
  }
}