.admin-container {
    height: 100vh;
}





/* Basic styling for the navbar */
.navbar {
    background-color: transparent;
    overflow: hidden;
    padding: 20px;
    cursor: pointer;
}

/* Style for the dropdown button */
.dropbtn {
    background-color: transparent;
    color: white;
    font-size: 0.875rem;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    letter-spacing: 0.02857em !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Hide the dropdown content by default */
.dropdown-content {
    display: none;
    position: absolute !important;
    background-color: #10cb7f;
    box-shadow: 0px 20px 16px 0px rgba(56, 56, 56, 0.2);
    z-index: 1;
    width: 100%;
    top: 100%;
    right: 0%;
    z-index: 99999;
    padding: 20px 0px;
}

.goveda-dropdown-content {
    display: none;
    position: absolute !important;
    background-color: #10cb7f;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: auto !important;
    top: 100%;
    right: 60%;
    z-index: 999;
    transition: transform 30s ease;
    /* Apply the transition to transform */
}

/* Hover effect for links */
.goveda-dropdown-content a:hover {
    transform: scale(0.99);
    background-color: rgba(16, 109, 49, 0.404) !important;
    /* This will now transition properly */
}



/* Mega-dropdown layout */
.mega-dropdown {
    display: flex;
    padding: 10px;
    justify-content: space-around;
}

/* Style each column inside the mega-dropdown */
.mega-dropdown .column {
    width: 200px;
}

/* Style links inside each column */
.mega-dropdown a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border-bottom: 1px solid grey;

}

.mega-dropdown a:hover {
    background-color: #ddd;
}

/* Show the dropdown content when hovering over the dropdown */
.navbar:hover .dropdown-content {
    display: block;
}


/* Style for category headings */
.mega-dropdown h3 {
    margin: 0;
    padding-bottom: 10px;
    font-size: 18px;
}


.recharts-wrapper .recharts-cartesian-grid {
    width: 500px !important;
}

.stsearch-box input {
    border: none !important;
    padding: 15px !important;
    font-size: medium !important;
}

.react-tel-input {
    height: 50px !important;
    width: 200px !important;
}

.react-tel-input .form-control {
    height: 50px !important;
    width: 100px !important;
}

.disabled-dropdown {
    pointer-events: none !important;
    opacity: 0.7;
}

/* admin report dropdown */

/* Dropdown container */
.admin-report-dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown button */
.admin-report-dropdown-button {
    background-color: transparent;
    color: black;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    padding: 10px 0px;
}

.admin-report-dropdown-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Dropdown content */
.admin-report-dropdown-content {
    display: none;
    position: absolute;
    background-color: #10cb7f;
    color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    min-width: 160px;
    z-index: 1;
}

.admin-report-dropdown-content a {
    text-decoration: none;
    color: black;
    padding: 12px 16px;
    display: block;
}

.admin-report-dropdown-content a:hover {
    background-color: #ddd;
}

/* Show dropdown content on hover */
.admin-report-dropdown:hover .admin-report-dropdown-content {
    display: block;
}